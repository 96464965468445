<template>
  <div id="all">
    <router-view id="app" />
    <p v-if="!isOnline" class="app__offline-message">Vous êtes actuellement hors ligne</p>
  </div>
</template>

<script lang="ts">
import { provideMediaService } from '@b2ag/medias'
import { provideRouter } from '@b2ag/composables'
import { provideStore } from '@b2ag/store'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { provideFocusIndex } from '@b2ag/composables/src/catalog/useDiscount'
import { computed, defineComponent } from 'vue'
import { getAppTypeLocale } from '@b2ag/locale'
import { provideAddressReactivitySettings } from '@b2ag/soufflet-pricer'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'
import { getDefaultSEODescription } from '@b2ag/utils/src/SEO/description'
import { currentAppBrand } from '@b2ag/app-brands'
import { useCategoriesSlugs } from '@b2ag/search/src/composables/useCategoriesSlugs'
import { provideCategoryDescription } from '@b2ag/marketing/src/application/composables/useCategoryDescription'
import { provideShoppingList } from '@b2ag/shopping-list/src/composables/useShoppingList'
import { provideMembershipStore } from '@b2ag/membership'
import { provideCurrentMembership } from '@b2ag/membership/src/composables/useCurrentMembership'
import { getAppContext } from '@b2ag/micro-front-tools'
import type { Store } from '@b2ag/store'
import { makeAddressReactivitySettings } from './contexts/soufflet-pricer'
import { getRouter } from './router'
import { getStore } from './store'

const appTypeLocale = getAppTypeLocale(currentAppBrand)

export default defineComponent({
  name: 'App',
  setup() {
    window.env.APP_CONTEXT = currentAppBrand

    const store = getStore() as Store
    const isOnline = computed(() => store.getters.isOnline)
    const { appFrontType } = getAppContext()

    provideStore(store)
    provideRouter(getRouter())

    const mediaApiClient = createAxiosClientWithAladinBearer(`${window.env.MEDIA_API_HOST}/v1`, {
      callerId: buildCallerId(),
    })

    provideMediaService(mediaApiClient)
    provideAddressReactivitySettings(makeAddressReactivitySettings(store))
    provideFocusIndex()

    switch (appFrontType) {
      case 'agri':
        provideCategoryDescription()
        break
      case 'tech':
        provideCurrentMembership(store)
        provideMembershipStore(store)
        provideShoppingList()
        break
      default:
        break
    }

    useCategoriesSlugs()

    // XJU - 30/01/2024 : pas trouvé mieux pour le moment
    if (isWebViewFarmi()) {
      const styles = document.createElement('style')
      styles.setAttribute('type', 'text/css')
      styles.textContent = `:root { --mobile-header-height: 58px !important; }`
      document.head.appendChild(styles)
    }

    const styles = document.createElement('style')
    styles.setAttribute('type', 'text/css')
    styles.textContent = `:root { --desktop-header-height: 66px !important; }`
    document.head.appendChild(styles)

    return {
      isOnline,
    }
  },
  metaInfo() {
    return {
      title: appTypeLocale.metadata.title,
      link: [
        // @ts-ignore à voir au passage en Vue3
        { vmid: 'canonical', rel: 'canonical', href: `${window.location.origin}${this.$route.fullPath}` },
        {
          rel: 'alternate',
          hreflang: 'fr',
          // @ts-ignore à voir au passage en Vue3
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
      meta: [
        { vmid: 'robots', name: 'robots', content: 'index, follow' },
        { vmid: 'description', name: 'description', content: getDefaultSEODescription(currentAppBrand) },
      ],
    }
  },
})
</script>

<style lang="scss">
@import '@b2ag/themes/common/lib/bootstrap/index.scss';
@import '@b2ag/themes/common/styles/index.scss';
@import '@b2ag/themes/common/styles/root.scss';
@import '@b2ag/themes/current/styles/fonts.css';

html {
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
  color: $color-text;
  background-color: $color-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  color: $color-main;

  &:hover {
    color: $color-main-dark;
  }
}

hr {
  margin: 0 1rem;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-full-screen {
  width: 100%;
  @media (min-width: $breakpoint-sm) {
    margin: 0 1em 0 0;
    width: auto;
  }
}

.adn-button .spinner-border,
.btn .spinner-border {
  vertical-align: middle;
  margin-left: 10px;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-position: center;
  background-size: cover;
}

#all {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

#minimal {
  flex: 1;

  .main {
    flex: 1;
    height: 100vh;
    background: $color-white;
    margin-top: 0;
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }
  }
}

.app__offline-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: $color-warning;
  color: $color-text-lightest;
  text-align: center;
  margin: 0;
  line-height: 30px;
  z-index: 1000;
}

.emphasis__block {
  @media screen and (min-width: 576px) {
    padding: 1em;
    background-color: $color-background;
    color: $color-text;
  }
  padding: 2em 1em;
  background-color: $color-background-darkest;
  color: $color-background-light;
}

.modal-footer {
  .btn:not(:first-child) {
    margin-left: 0.4rem;
  }

  .btn:not(:last-child) {
    margin-right: 0.4rem;
  }
}

.main-title {
  font-size: 1.2em;
  font-weight: bold;
  color: $color-text;
  text-align: left;
  @media (min-width: $breakpoint-lg) {
    text-align: center;
  }

  strong {
    text-transform: uppercase;
    color: $color-main;
  }
}

.container--full-width-on-mobile {
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin: 0;
    max-width: 100%;
  }
}

.content-block {
  @media (min-width: $breakpoint-md) {
    margin-bottom: 2em;
  }
}

.form-check-label {
  cursor: pointer;
}

option:disabled {
  color: $color-inactive-light;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: $color-text;
  padding: 9px 12px;
  height: 41px;
  font-size: $font-size-s;
  border: 1px solid $color-border-light;
  border-radius: $border-radius-1;
  background-color: $color-white;
  box-shadow: none;

  &::placeholder,
  & input::placeholder {
    font-size: $font-size-s;
    color: $color-text-light;
    @media (min-width: $breakpoint-sm) {
      font-size: 1.6rem;
    }
  }

  &:focus {
    box-sizing: border-box;
    border: 1px solid $color-border;
    box-shadow: none;
    outline: transparent;
  }

  &.login-form__input-error {
    border-color: $color-error;
  }
}

textarea {
  min-height: 64px;
  height: auto;
}

.placeholder-loading {
  background-color: $color-inactive-light;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    background: linear-gradient(90deg, $color-inactive-light 0%, $color-background 50%, $color-inactive-light 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 1.5s placeholder-loading ease-in-out infinite;
  }
}
@keyframes placeholder-loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
