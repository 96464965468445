import type { MembershipApi } from '@b2ag/membership'
import type { MembershipService } from '@b2ag/membership/src/services/membership.service'
import {
  type ShoppingListApi,
  type ShoppingListApiClient,
  ShoppingListApiErrorType,
} from '@aladin/shared-shopping-list-sdk'
import sdk from '@invivodf/module-appro-bff-sdk'
import { getConfig } from '@b2ag/micro-front-tools'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { v4 as uuidv4 } from 'uuid'
import { i18n } from '@b2ag/locale'
import { useAuthService } from '@b2ag/auth'
import { transformResponse } from '@aladin/shared-shopping-list-sdk/dist/transform-response'

import type { TargetShoppingListToList } from '@b2ag/target-membership/src/domain/target-membership.interface'
import { mapShoppingListToList } from '@b2ag/target-membership/src/infra/target-membership.mappers'
import type { ShoppingList } from '../domain/shopping-list.model'
import { modelToSaveShoppingListPayloadApi, shoppingListApiToModel } from './shopping-list.mappers'

interface Dependencies {
  shoppingListDataSource: ShoppingListApiClient
  membershipService: MembershipService
}

export interface ShoppingListRepository {
  fetchShoppingList(id: string): Promise<ShoppingList | null>
  saveShoppingList(shoppingList: ShoppingList): Promise<ShoppingList>
  sendShoppingList(shoppingListId: string): Promise<ShoppingList>
  orderShoppingList(shoppingListId: string): Promise<ShoppingList>
  flushShoppingListValidationErrors(shoppingListId: string): Promise<ShoppingList | null>
  removeShoppingList(shoppingListId: string): Promise<void>
  duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }>
  checkShoppingListMembership(params: { partnerId: number; membershipNumber: string }): Promise<boolean>
}

export function createShoppingListRepository({
  shoppingListDataSource,
  membershipService,
}: Dependencies): ShoppingListRepository {
  async function fetchShoppingList(id: string): Promise<ShoppingList | null> {
    const shoppingListApi = await shoppingListDataSource.fetchOwnedShoppingList(id)
    if (shoppingListApi === null) {
      return null
    }
    return shoppingListApiToModel(shoppingListApi)
  }

  async function saveShoppingList(shoppingList: ShoppingList): Promise<ShoppingList> {
    const payload = modelToSaveShoppingListPayloadApi(shoppingList)
    const shoppingListApiSaved = await shoppingListDataSource.saveShoppingList(payload)
    return shoppingListApiToModel(shoppingListApiSaved)
  }

  async function sendShoppingList(shoppingListId: string): Promise<ShoppingList> {
    let shoppingListApi: ShoppingListApi
    try {
      shoppingListApi = await shoppingListDataSource.sendShoppingList(shoppingListId)
      return shoppingListApiToModel(shoppingListApi)
    } catch (error: any) {
      if (
        error.key === ShoppingListApiErrorType.OUTDATED_SHOPPING_LIST_ERROR &&
        error?.details?.updated_shopping_list
      ) {
        const { updated_shopping_list: updatedShoppingListApi } = error.details
        const sendError = {
          key: error.key,
          message: error.message,
          details: {
            updatedShoppingList: shoppingListApiToModel(updatedShoppingListApi),
          },
        }
        throw sendError
      }
      throw error
    }
  }

  async function orderShoppingList(shoppingListId: string): Promise<ShoppingList> {
    try {
      return shoppingListApiToModel(await shoppingListDataSource.checkoutV2ShoppingList(shoppingListId))
    } catch (error: any) {
      if (
        error.key === ShoppingListApiErrorType.OUTDATED_SHOPPING_LIST_ERROR &&
        error?.details?.updated_shopping_list
      ) {
        const { updated_shopping_list: updatedShoppingListApi } = error.details
        const sendError = {
          key: error.key,
          message: error.message,
          details: {
            updatedShoppingList: shoppingListApiToModel(updatedShoppingListApi),
          },
        }
        throw sendError
      }
      throw error
    }
  }

  async function flushShoppingListValidationErrors(shoppingListId: string): Promise<ShoppingList | null> {
    const shoppingListApi = await shoppingListDataSource.flushShoppingListValidationErrors(shoppingListId)
    if (shoppingListApi === null) {
      return null
    }
    return shoppingListApiToModel(shoppingListApi)
  }

  async function removeShoppingList(shoppingListId: string): Promise<void> {
    await shoppingListDataSource.removeShoppingList(shoppingListId)
  }

  async function duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }> {
    const apiHost = getConfig('SHOPPING_LIST_API_HOST')
    const authService = useAuthService()
    const bffSdk = sdk(apiHost, {
      callerId: buildCallerId(),
      accessTokenSource: () => authService.getAccessToken(),
    })
    const shoppingListDuplicated = await bffSdk.duplicateList({
      listTitle: `${i18n._('Liste')} ${uuidv4().substring(0, 6).toUpperCase()}`,
      shoppingListId: params.shoppingListId,
      partner_id: params.partnerId,
      recipient: {
        ...params.recipient,
      },
    })

    const shoppingListTransformed = transformResponse(JSON.stringify(shoppingListDuplicated))

    return {
      shoppingList: shoppingListApiToModel(shoppingListTransformed),
      shoppingListToList: mapShoppingListToList(shoppingListTransformed),
    }
  }
  async function checkShoppingListMembership(params: {
    partnerId: number
    membershipNumber: string
  }): Promise<boolean> {
    try {
      const result = await membershipService.get(params.partnerId, params.membershipNumber)
      if (!result) {
        return true
      }
      return false
    } catch (error: any) {
      if (error.message.includes('403')) {
        return true
      }
      return false
    }
  }

  return {
    fetchShoppingList,
    saveShoppingList,
    sendShoppingList,
    orderShoppingList,
    flushShoppingListValidationErrors,
    removeShoppingList,
    duplicateShoppingList,
    checkShoppingListMembership,
  }
}
