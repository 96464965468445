var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"adn-input-text"},[(_vm.label)?_c('label',{staticClass:"adn-input-text__label",class:{
      'adn-input-text__label--disabled': _vm.disabled,
      'adn-input-text__label--error': _vm.errorMessage,
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"adn-input-text__wrapper",class:{
      'adn-input-text__wrapper--disabled': _vm.disabled,
      'adn-input-text__wrapper--error': _vm.errorMessage,
    }},[_c('div',{staticClass:"adn-input-text__container",class:{
        'adn-input-text__container--disabled': _vm.disabled,
      }},[(_vm.iconLeft)?_c('span',{staticClass:"adn-input-text__icon adn-input-text__icon-left adn-icon",class:_vm.iconLeftClassNames,attrs:{"aria-hidden":"true"}}):_vm._e(),_c('input',{staticClass:"adn-input-text__input",class:_vm.classNames,attrs:{"id":_vm.id,"name":_vm.name,"required":_vm.required,"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled,"aria-invalid":_vm.errorMessage ? 'true' : false,"aria-describedby":_vm.errorMessage ? `error${_vm.id}` : undefined},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.emit($event)}}}),(_vm.iconRight)?_c('span',{staticClass:"adn-input-text__icon adn-input-text__icon-right adn-icon",class:[_vm.iconRightClassNames, { 'adn-input-text__icon--clickable': _vm.hasRightClick }],attrs:{"aria-hidden":"true","data-test-icon-right":""},on:{"click":_vm.iconRightClick}}):_vm._e()])]),(_vm.errorMessage)?_c('div',{staticClass:"adn-input-text__error",attrs:{"data-test-error":""}},[_c('span',{staticClass:"adn-icon adn-icon--status-unavailable",attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"id":`error${_vm.id}`}},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }