import Big from 'big.js'

import { formatPrecisePriceNumber, formatPriceNumber } from '@b2ag/l10n'
import Discount from '../Discount'

export default class Price {
  private readonly internalValue: Big

  private readonly precise: boolean

  constructor(value: number | Big | Price, precise = false) {
    this.internalValue = Big(+value)
    this.precise = precise
  }

  applyDiscount(discount: Discount): Price {
    return new Price(
      discount.unit === '%'
        ? this.internalValue.minus(this.internalValue.mul(discount.discount).div(100))
        : this.internalValue.minus(discount.discount),
      this.precise,
    )
  }

  toString() {
    return this.precise ? formatPrecisePriceNumber(+this) : formatPriceNumber(+this)
  }

  valueOf() {
    return +this.internalValue
  }

  get value() {
    return +this.internalValue
  }

  applyQuantity(quantity: number): Price {
    return new Price(this.internalValue.mul(quantity), this.precise)
  }
}
