import type { AxiosInstance } from '@b2ag/axios'

type Partner = {
  id: number
  name: string
  customer_support_opening_hours?: string
  customer_supportphone_no?: string
  customer_support_email?: string
  terms_of_sale?: string
  created_at: string
  updated_at: string
}

export class PartnerService {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private izanamiApiClient: Pick<AxiosInstance, 'get'>,
    private partnerApiClient: Pick<AxiosInstance, 'get' | 'post'>,
    private appContext = 'aladin',
  ) {}

  async retrievesHiddenCooperatives(): Promise<number[]> {
    try {
      const { data } = await this.izanamiApiClient.get(`/api/features/${this.appContext}:front-agri:hide-cooperatives`)

      if (data.enabled === false) {
        return []
      }

      return data.parameters.customers.map((item) => parseInt(item, 10)).filter((item) => item)
    } catch (error: any) {
      return []
    }
  }

  // TODO: doublon avec cooperative.service
  async getPartners() {
    return (await this.partnerApiClient.get<Partner[]>('/cooperatives')).data
  }

  async newPartnerAlert(partnerName: string): Promise<unknown> {
    return this.partnerApiClient.post('/partners/send-new-partner-request-email-alert', { partner_name: partnerName })
  }
}
