import type { Offer } from '@b2ag/offers/src/offer'
import type { Offer as PricingOffer } from '@b2ag/pricing'
import type {
  Product as PricingProductProps,
  Variant as PricingVariantProps,
} from '@b2ag/pricing/src/domain/pricing.interface'
import isEmpty from 'lodash/isEmpty'
import type { CooperativeProduct } from './product'

export type FullVariant<T extends PricingVariantProps> = T & {
  offers: Offer[]
}

export type FullProduct<T extends PricingProductProps> = Omit<T, 'variants'> & {
  variants: FullVariant<T['variants'][number]>[]
  bestOffer: PricingOffer | null
  bestOfferId: string | null
  bestOfferPriceType: string | null
}

function computeVariantListWithoutOffer(product?: FullProduct<CooperativeProduct>) {
  return product?.variants?.filter((variant) => variant.sellable && isEmpty(variant.offers)) || []
}

function computeVariantListWithOffer(product?: FullProduct<CooperativeProduct>) {
  return product?.variants?.filter((variant) => variant.sellable && !isEmpty(variant.offers)) || []
}

export { computeVariantListWithoutOffer, computeVariantListWithOffer }
