import Big from 'big.js'

import Discount from './Discount'
import {
  type DiscountApplication,
  type DiscountApplicationProps,
  DiscountOnQuantity,
  DiscountOnRevenue,
  DiscountSimple,
} from './DiscountApplication'
import { DISCOUNT_TYPE } from './constants/DISCOUNT_TYPE'
import DetailPrice from './domain-objects/DetailPrice'
import DiscountAmount from './domain-objects/DiscountAmount'
import { DiscountScale } from './domain-objects/DiscountScale'
import Price from './domain-objects/Price'

// une offre a un conditionnement
// un conditionnement est une quantité (à l'unité de mesure) pour la vente ex: 28.8T est un conditionnement
// On peut choisir de travailler le prix de l'offre au conditionnement ou à l'unité de mesure

export interface IOfferProps {
  isVariantSoldAsUnit: boolean
  variantMeasureUnit: string
  variantMeasureQuantity: number
  variantPackaging?: string
  unitPriceExcludingTaxes: number
  vat: number
  measurementPriceExcludingTaxes?: number
  rpdPrice?: number
  rpdUnit?: string
  discountApplication?: DiscountApplicationProps
}

function discountApplicationFactory(discountApplication: DiscountApplicationProps): DiscountApplication | undefined {
  if (discountApplication.type === DISCOUNT_TYPE.QUANTITATIVE && discountApplication.slices !== null) {
    return new DiscountOnQuantity(new DiscountScale(discountApplication.slices), discountApplication.offerIdList)
  }
  if (discountApplication.type === DISCOUNT_TYPE.REVENUE && discountApplication.slices !== null) {
    return new DiscountOnRevenue(new DiscountScale(discountApplication.slices), discountApplication.offerIdList)
  }
  if (discountApplication.type === DISCOUNT_TYPE.SIMPLE && discountApplication.amount !== null) {
    return new DiscountSimple(new DiscountAmount(discountApplication.amount), discountApplication.offerIdList)
  }

  return undefined
}

export default class Offer {
  public readonly isVariantSoldAsUnit: boolean

  public readonly variantMeasureUnit: string

  public readonly variantMeasureQuantity: number

  public readonly unitPriceExcludingTaxes: Price

  public readonly measurementPriceExcludingTaxes: Price

  public readonly rpdPrice: Price | null

  public readonly rpdUnit: string | null

  public readonly discountApplication?: DiscountApplication

  public readonly detailPrice: DetailPrice

  public readonly variantPackaging: string | null

  constructor(offerProps: IOfferProps) {
    this.isVariantSoldAsUnit = offerProps.isVariantSoldAsUnit
    this.variantMeasureUnit = offerProps.variantMeasureUnit
    this.variantMeasureQuantity = offerProps.variantMeasureQuantity
    this.unitPriceExcludingTaxes = new Price(offerProps.unitPriceExcludingTaxes)
    this.rpdPrice = offerProps.rpdPrice ? new Price(offerProps.rpdPrice, true) : null
    this.rpdUnit = offerProps.rpdUnit ? offerProps.rpdUnit : null
    this.measurementPriceExcludingTaxes = offerProps.measurementPriceExcludingTaxes
      ? new Price(offerProps.measurementPriceExcludingTaxes)
      : new Price(Big(offerProps.unitPriceExcludingTaxes).div(this.variantMeasureQuantity))
    this.discountApplication =
      offerProps.discountApplication && discountApplicationFactory(offerProps.discountApplication)
    this.detailPrice = new DetailPrice(this.unitPriceExcludingTaxes, offerProps.vat, this.rpdAmount() || undefined)
    this.variantPackaging = offerProps.variantPackaging ? offerProps.variantPackaging : null
  }

  rpdAmount(): number | null {
    if (this.rpdUnit !== '€/unité' && this.rpdPrice instanceof Price) {
      return +Big(this.variantMeasureQuantity).mul(+this.rpdPrice)
    }
    return this.rpdPrice && +this.rpdPrice
  }

  hasDiscount() {
    return !!this.discountApplication
  }

  bestPossibleDiscount(): Discount | null {
    if (this.discountApplication) {
      return this.discountApplication.bestDiscount()
    }
    return null
  }

  discountFrom(value: number): Discount | undefined {
    return this.discountApplication?.getDiscount(value)
  }

  bestPossibleMeasurementPriceExcludingTaxes() {
    const bestPossibleDiscount = this.bestPossibleDiscount()
    if (bestPossibleDiscount) {
      return this.measurementPriceExcludingTaxes.applyDiscount(
        bestPossibleDiscount.appliedInMeasureUnit(this.variantMeasureQuantity),
      )
    }
    return this.measurementPriceExcludingTaxes
  }

  bestPossibleUnitPriceExcludingTaxes() {
    const bestPossibleDiscount = this.bestPossibleDiscount()
    if (bestPossibleDiscount) {
      return this.unitPriceExcludingTaxes.applyDiscount(bestPossibleDiscount.appliedInUnit(this.variantMeasureQuantity))
    }
    return this.unitPriceExcludingTaxes
  }

  unitPriceHT(discountProjection: number): Price {
    const discount = this.discountFrom(discountProjection)
    return discount ? this.unitPriceExcludingTaxes.applyDiscount(discount) : this.unitPriceExcludingTaxes
  }

  measurementPriceHT(discountProjection: number): Price {
    const discount = this.discountFrom(discountProjection)
    return discount ? this.measurementPriceExcludingTaxes.applyDiscount(discount) : this.measurementPriceExcludingTaxes
  }

  totalPriceHT(quantity: number, discountProjection: number): Price {
    return this.isVariantSoldAsUnit
      ? this.unitPriceHT(discountProjection).applyQuantity(quantity)
      : this.measurementPriceHT(discountProjection).applyQuantity(this.variantMeasureQuantity).applyQuantity(quantity)
  }

  totalHTPriceBeforeDiscount(quantity: number): Price {
    return this.isVariantSoldAsUnit
      ? this.unitPriceExcludingTaxes.applyQuantity(quantity)
      : this.measurementPriceExcludingTaxes.applyQuantity(this.variantMeasureQuantity).applyQuantity(quantity)
  }

  totalRpdPrice(quantity: number): Price | null {
    const rpd = this.rpdAmount()
    return rpd === null ? null : new Price(rpd, true).applyQuantity(quantity)
  }

  applyQuantityOnMeasurementPriceExcludingTaxes(quantity: number): Price {
    return this.measurementPriceExcludingTaxes.applyQuantity(quantity)
  }

  applyQuantityOnUnitPriceExcludingTaxes(quantity: number): Price {
    return this.unitPriceExcludingTaxes.applyQuantity(quantity)
  }
}
