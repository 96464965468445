import type { AxiosInstance } from '@b2ag/axios'
import { getConfig } from '@b2ag/micro-front-tools'
import { PartnerService } from './partner.service'

let allHiddenCooperatives: number[] | null
let partnerService: PartnerService | undefined

export function getPartnerLogoUrl(cloudinaryBucketName: string, partnerId: number) {
  return `https://res.cloudinary.com/${cloudinaryBucketName}/image/upload/h_140,w_140,g_auto,c_auto_pad,q_auto,f_auto,dpr_auto/logos/cooperatives/cooperative_${+partnerId}.jpg`
}

export function usePartner() {
  async function getAvailableCooperatives() {
    if (!partnerService) throw new Error('partner service not initialized')
    else if (!allHiddenCooperatives) throw new Error('allHiddenCooperatives not initialized')

    const partners = await partnerService.getPartners()
    return partners.filter((partner) => !allHiddenCooperatives?.includes(partner.id))
  }
  return {
    getAllHiddenCooperatives: (): number[] => allHiddenCooperatives || [],
    isHiddenCooperative: (partnerId: string) => {
      return allHiddenCooperatives && allHiddenCooperatives?.length > 0
        ? allHiddenCooperatives.includes(parseInt(partnerId, 10))
        : false
    },
    updateHiddenCooperatives: async () => {
      if (!partnerService) throw new Error('PartnerService is not initialized')
      if (!allHiddenCooperatives) {
        allHiddenCooperatives = await partnerService.retrievesHiddenCooperatives()
      }
    },
    getAvailableCooperatives,
    async getAvailableCooperativesLogos() {
      const partners = await getAvailableCooperatives()
      return partners.map((partner) => ({
        id: partner.id,
        url: getPartnerLogoUrl(getConfig('CLOUDINARY_BUCKET_NAME'), partner.id),
        alt: `Coopérative ${partner.name}`, // TODO: i18n
      }))
    },
    async suggestNewPartner(partnerName: string) {
      return partnerService?.newPartnerAlert(partnerName)
    },
  }
}

export type PartnerFunction = ReturnType<typeof usePartner>

export function providePartner(
  izanamiApiClient: Pick<AxiosInstance, 'get'>,
  partnerApiClient: Pick<AxiosInstance, 'get' | 'post'>,
  appContext = 'aladin',
) {
  allHiddenCooperatives = null
  partnerService = new PartnerService(izanamiApiClient, partnerApiClient, appContext)
}
