import { measureUnit } from '../unit/measure'

const NUMBER_SETTINGS = { maximumFractionDigits: 2 } as const
const ROUND_NUMBER_SETTINGS = { maximumFractionDigits: 0 } as const
const PRECISE_NUMBER_SETTINGS = { maximumFractionDigits: 5 } as const

const PRICE_NUMBER_SETTINGS = { ...NUMBER_SETTINGS, minimumFractionDigits: 2 } as const
const ROUND_PRICE_NUMBER_SETTINGS = ROUND_NUMBER_SETTINGS
const PRECISE_PRICE_NUMBER_SETTINGS = { ...PRECISE_NUMBER_SETTINGS, minimumFractionDigits: 2 } as const

const PRICE_SETTINGS = {
  ...PRICE_NUMBER_SETTINGS,
  style: 'currency',
  currency: 'EUR',
} as const

const ROUND_PRICE_SETTINGS = {
  ...ROUND_PRICE_NUMBER_SETTINGS,
  style: 'currency',
  currency: 'EUR',
} as const

const PRECISE_PRICE_SETTINGS = {
  ...PRECISE_PRICE_NUMBER_SETTINGS,
  style: 'currency',
  currency: 'EUR',
} as const

export const { format: formatNumber } = new Intl.NumberFormat('fr-FR', NUMBER_SETTINGS)

export const { format: formatPreciseNumber } = new Intl.NumberFormat('fr-FR', PRECISE_NUMBER_SETTINGS)

export const { format: formatNumber3Digits } = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 3 })

export const { format: formatPriceNumber } = new Intl.NumberFormat('fr-FR', PRICE_NUMBER_SETTINGS)

export const { format: formatPrecisePriceNumber } = new Intl.NumberFormat('fr-FR', PRECISE_PRICE_NUMBER_SETTINGS)

export const { format: formatPrice } = new Intl.NumberFormat('fr-FR', PRICE_SETTINGS)

/**
 * @deprecated
 * @see {@link formatPrice}
 */
export const formatEuros = formatPrice

export const { format: formatRoundPrice } = new Intl.NumberFormat('fr-FR', ROUND_PRICE_SETTINGS)

/**
 * @deprecated
 * @see {@link formatRoundPrice}
 */
export const formatRoundEuros = formatRoundPrice

export const { format: formatPrecisePrice } = new Intl.NumberFormat('fr-FR', PRECISE_PRICE_SETTINGS)

export const formatQuantity = (n: number, unit?: string) =>
  unit ? `${formatNumber3Digits(n)}\xa0${measureUnit(unit, n)}` : formatNumber3Digits(n)

export const formatMeasureUnit = (n: number, unit?: string) =>
  unit ? `${formatNumber(n)}\xa0${measureUnit(unit, n)}` : formatNumber(n)
