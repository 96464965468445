import { getAppContext } from '@b2ag/micro-front-tools'

const { appFrontType } = getAppContext()

const routerAgri = () => import(/* webpackChunkName: "Router.agri" */ './fronts/agri/router')
const routerTech = () => import(/* webpackChunkName: "Router.tech" */ './fronts/tech/router')

let router: any

export async function initRouter() {
  router = appFrontType === 'agri' ? (await routerAgri()).default : (await routerTech()).default

  return router
}

export function getRouter() {
  return router
}
