import Big from 'big.js'
import { computed, type ComputedRef, inject, provide, reactive } from 'vue'
import { isSouffletPricerOfferId } from '@b2ag/soufflet-pricer'
import { useLogger } from '@b2ag/composables/src/useLogger'
import type { MembershipApi } from '@b2ag/membership'
import type { TargetShoppingListToList } from '@b2ag/target-membership/src/domain/target-membership.interface'
import {
  checkShoppingListMembershipUseCase,
  duplicateShoppingListUseCase,
  fetchOwnedShoppingListUseCase,
  flushShoppingListValidationErrorsUseCase,
  orderShoppingListUseCase,
  removeLineFromShoppingListUseCase,
  removeShoppingListUseCase,
  saveShoppingListUseCase,
  sendShoppingListUseCase,
} from '../dependency-injection'
import { hasCertiphytoInvalidError, hasExplosivePrecursorInvalidError } from '../domain/certificate-errors'
import type { ShoppingList, ShoppingListItem } from '../domain/shopping-list.model'
import { isShoppingListOutdatedError } from '../domain/validation-errors'

export interface ShoppingListStore {
  state: ShoppingListState
  fetchOwnedShoppingList(id: string): Promise<void>
  saveCurrentShoppingList(): Promise<void>
  addCheckoutNotFoundErrorToShoppingList(): void
  addDeletedMembershipErrorToShoppingList(cooperativeName: string): void
  saveComment(comment: string): Promise<void>
  saveTitle(title: string): Promise<void>
  removeLineFromShoppingList(line: ShoppingListItem): Promise<void>
  sendShoppingList(): Promise<void>
  orderShoppingList(): Promise<void>
  removeShoppingList(): Promise<void>
  flushShoppingListValidationErrors(id: string): Promise<void>
  fetchQuantityMax(item: ShoppingListItem): number
  hasClickAndCollectLines(): boolean
  duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList } | undefined>
  checkShoppingListMembership(params: { partnerId: number; membershipNumber: string }): Promise<boolean>
  displayError(message: string): void
  containsPricerOffers: ComputedRef<boolean>
  resetError(): void
}

export interface ShoppingListState {
  current: ShoppingList | null
  isLoading: boolean
  isSaving: boolean
  hasError: boolean
  hasCertiphytoInvalidError: boolean
  hasExplosivePrecursorInvalidError: boolean
  errorMessage: string | null
}

export function createShoppingListStore(): ShoppingListStore {
  const logger = useLogger('store:shopping-list')
  const state = reactive<ShoppingListState>({
    isLoading: false,
    isSaving: false,
    hasError: false,
    current: null,
    hasCertiphytoInvalidError: false,
    hasExplosivePrecursorInvalidError: false,
    errorMessage: '',
  })

  /**
   * SLR:
   * Premier essai de synchronisation store <> state
   * À essayer sur CTargetMembership
   */
  // watch(
  //   () => state.current?.id,
  //   (curr, prev) => {
  //     console.log({ prev, curr })
  //     if (curr && curr !== prev) {
  //       const store = useStore()
  //       store.dispatch('shoppingList/fetchShoppingList', curr)
  //     }
  //   },
  //   { immediate: true }
  // )

  async function saveComment(comment: string): Promise<void> {
    if (state.current === null) return
    state.current.comment = comment
    await saveCurrentShoppingList()
  }

  async function saveTitle(title: string): Promise<void> {
    if (state.current === null) return
    state.current.title = title
    await saveCurrentShoppingList()
  }

  async function sendShoppingList(): Promise<void> {
    try {
      if (state.current === null) return
      state.current = await sendShoppingListUseCase(state.current.id)
    } catch (error: any) {
      if (isShoppingListOutdatedError(error)) {
        state.current = error.details.updatedShoppingList
        checkCertificatesErrors(state.current)
      }
      throw error
    }
  }

  async function orderShoppingList(): Promise<void> {
    try {
      if (state.current === null) return
      state.current = await orderShoppingListUseCase(state.current.id)
    } catch (error: any) {
      if (isShoppingListOutdatedError(error)) {
        state.current = error.details.updatedShoppingList
        checkCertificatesErrors(state.current)
      }
      throw error
    }
  }

  async function removeShoppingList(): Promise<void> {
    if (state.current === null) return
    await removeShoppingListUseCase(state.current.id)
    state.current = null
  }

  async function saveCurrentShoppingList(): Promise<void> {
    if (state.current === null) return
    try {
      state.isSaving = true
      state.current = await saveShoppingListUseCase(state.current)
    } catch (err: any) {
      logger.error('Error while saving current shopping list', err)
    } finally {
      state.isSaving = false
    }
  }

  function addCheckoutNotFoundErrorToShoppingList(): void {
    if (state.current === null) return
    state.current?.errors.push({
      key: 'checkoutNotFoundError',
      message: 'Une erreur est survenue lors du passage de la commande. Veuillez réessayer.',
      name: 'checkoutNotFoundError',
    })
  }

  function addDeletedMembershipErrorToShoppingList(cooperativeName: string): void {
    const DELETED_MEMBERSHIP_ERROR = 'deletedMembership'
    if (state.current === null) return
    if (!state.current.errors.find((e) => e.key === DELETED_MEMBERSHIP_ERROR)) {
      state.current?.errors.push({
        key: DELETED_MEMBERSHIP_ERROR,
        message: `L'adhérent sélectionné ne fait plus parti du portefeuille d'adhérents de ${cooperativeName}`,
        name: DELETED_MEMBERSHIP_ERROR,
      })
    }
  }

  async function fetchOwnedShoppingList(id: string): Promise<void> {
    try {
      state.isLoading = true
      state.current = (await fetchOwnedShoppingListUseCase(id)) as ShoppingList
      checkCertificatesErrors(state.current)
    } catch (err: any) {
      logger.error('Error while fetching shopping list', err)
      state.hasError = true
    } finally {
      state.isLoading = false
    }
  }

  function checkCertificatesErrors(shoppingList: ShoppingList) {
    state.hasCertiphytoInvalidError = hasCertiphytoInvalidError(shoppingList)
    state.hasExplosivePrecursorInvalidError = hasExplosivePrecursorInvalidError(shoppingList)
  }

  async function removeLineFromShoppingList(line: ShoppingListItem): Promise<void> {
    try {
      if (state.current) {
        state.current = await removeLineFromShoppingListUseCase(state.current, line)
      }
    } catch (err: any) {
      logger.error('Error while removing line from shopping list', err)
    }
  }

  async function flushShoppingListValidationErrors(id: string): Promise<void> {
    try {
      state.current = (await flushShoppingListValidationErrorsUseCase(id)) as ShoppingList
      checkCertificatesErrors(state.current)
    } catch (err: any) {
      logger.error('Error while fetching shopping list', err)
    }
  }

  function fetchQuantityMax(item: ShoppingListItem): number {
    const quota = getNormalizedQuantity(
      item.quotas?.quantityMax,
      item.variant.quantityPerUnit,
      item.variant.variantIsSoldAsUnit,
    )
    const stock = getNormalizedQuantity(item.stock, item.variant.quantityPerUnit, item.variant.variantIsSoldAsUnit)
    return Math.max(item.offer.quantityMin, Math.min(item.offer.quantityMax || Infinity, stock ?? Infinity, quota))
  }

  const getNormalizedQuantity = (value = Infinity, measureQuantity, isSoldAsUnit) => {
    const divider = isSoldAsUnit ? 1 : measureQuantity
    return value === Infinity ? value : Math.floor(Big(value).div(divider).toNumber()) // Big.js ne supporte pas Infinity
  }

  function hasClickAndCollectLines() {
    return !!state.current?.lines.find((item) => item.clickAndCollectStoreCode)
  }

  async function duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }> {
    if (state.current === null) throw new Error('Duplicate called on null Shopping list')
    let shoppingListDuplicated: { shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }
    try {
      state.isSaving = true
      shoppingListDuplicated = await duplicateShoppingListUseCase(params)
      state.current = shoppingListDuplicated.shoppingList
      return shoppingListDuplicated
    } catch (err: any) {
      logger.error('Error while duplicating current shopping list', err)
      state.isSaving = false
      throw err
    }
  }

  async function checkShoppingListMembership(params: {
    partnerId: number
    membershipNumber: string
  }): Promise<boolean> {
    return await checkShoppingListMembershipUseCase(params)
  }

  function displayError(message: string) {
    state.errorMessage = message
  }

  function resetError() {
    state.hasError = false
  }

  const containsPricerOffers = computed(() => {
    const lines = state.current?.lines ?? []
    return lines.some((item) => isSouffletPricerOfferId(item.offer.id))
  })

  return {
    state,
    fetchOwnedShoppingList,
    saveCurrentShoppingList,
    addCheckoutNotFoundErrorToShoppingList,
    addDeletedMembershipErrorToShoppingList,
    saveComment,
    saveTitle,
    removeLineFromShoppingList,
    sendShoppingList,
    orderShoppingList,
    flushShoppingListValidationErrors,
    fetchQuantityMax,
    removeShoppingList,
    hasClickAndCollectLines,
    duplicateShoppingList,
    displayError,
    checkShoppingListMembership,
    containsPricerOffers,
    resetError,
  }
}

const SHOPPING_LIST_SYMBOL = Symbol('SHOPPING_LIST_SYMBOL')

export function provideShoppingList() {
  provide<ShoppingListStore>(SHOPPING_LIST_SYMBOL, createShoppingListStore())
}

export function useShoppingList(): ShoppingListStore {
  const store = inject<ShoppingListStore>(SHOPPING_LIST_SYMBOL)
  if (!store) {
    throw new Error('ShoppingList store was not initialized.')
  }
  return store
}
