// eslint-disable-next-line import/no-extraneous-dependencies
import type { SearchForFacetValuesResponse, SearchOptions, SearchResponse } from '@algolia/client-search'
import { CULTURE_EQUIPMENT_KIND, FARMER_EQUIPMENTS_KIND } from '@b2ag/product/src/domain/products.constants'
import type { SearchClient, SearchIndex } from 'algoliasearch/lite'

import { getSecondsSinceEpoch } from './utils/getSecondsSinceEpoch'
import type { CategoryFacet, CategoryHit, Product, SearchResult, SearchService } from './search.service'

const CATEGORY_SEPARATOR = ' > '
const EXCLUDED_CATEGORIES_FROM_SUPPLIER_SEARCH = [CULTURE_EQUIPMENT_KIND, FARMER_EQUIPMENTS_KIND]

function getCategoryLabelAt(hierarchy, level) {
  return hierarchy.value.split(CATEGORY_SEPARATOR)[level].trim()
}

function getParentPath(categoryPath) {
  return categoryPath.value.substring(0, categoryPath.value.lastIndexOf(CATEGORY_SEPARATOR))
}

function getChildren(possibleChildren, currentCategory) {
  return possibleChildren && possibleChildren.filter((category) => getParentPath(category) === currentCategory.value)
}

export class AlgoliaSearchService implements SearchService {
  private searchIndex: SearchIndex | null

  private searchIndexCategories: SearchIndex | null

  constructor(private searchClient: SearchClient) {
    this.searchIndex = null
    this.searchIndexCategories = null
  }

  initSearchIndexes(searchIndexName: string, searchIndexCategoriesName: string): void {
    this.searchIndex = this.searchClient.initIndex(searchIndexName)
    this.searchIndexCategories = this.searchClient.initIndex(searchIndexCategoriesName)
  }

  initSearchIndex(searchIndexName: string): void {
    this.searchIndex = this.searchClient.initIndex(searchIndexName)
  }

  initSearchIndexCategories(searchIndexCategoriesName: string): void {
    this.searchIndexCategories = this.searchClient.initIndex(searchIndexCategoriesName)
  }

  async getFacetValues(facetName: string, facetFilters?: Array<string | string[]>): Promise<any[]> {
    const settings: SearchOptions = {
      facetFilters,
      sortFacetValuesBy: 'alpha',
      maxFacetHits: 100,
    }
    const results: SearchForFacetValuesResponse = await this.searchIndex!.searchForFacetValues(facetName, '*', settings)
    return results.facetHits
  }

  /* eslint-disable no-param-reassign */
  async getCategoriesFacets(): Promise<CategoryFacet[]> {
    const categoriesFacets = await this.getCategoriesFacetsList()
    const categoriesByPath = await this.getCategoriesByPath()
    return this.sortCategoryFacets(categoriesFacets, categoriesByPath)
  }

  async getCategories(): Promise<CategoryHit[]> {
    return this.fetchAllCategories()
  }

  private async getCategoriesFacetsList(): Promise<CategoryFacet[]> {
    if (!this.searchIndex) {
      return []
    }
    const { facets } = await this.searchIndex.search('', {
      hitsPerPage: 0,
      facets: ['categoriesLevel0', 'categoriesLevel1', 'categoriesLevel2', 'categoriesLevel3'],
      maxValuesPerFacet: 1000,
    })

    const categoriesLevel0 = Object.keys(facets?.categoriesLevel0 ?? {}).map((cat) => ({ value: cat }))
    const categoriesLevel1 = Object.keys(facets?.categoriesLevel1 ?? {}).map((cat) => ({ value: cat }))
    const categoriesLevel2 = Object.keys(facets?.categoriesLevel2 ?? {}).map((cat) => ({ value: cat }))
    const categoriesLevel3 = Object.keys(facets?.categoriesLevel3 ?? {}).map((cat) => ({ value: cat }))

    const categoriesFacets = categoriesLevel0.map((categoryLevel0) => {
      let hierarchyDepth = 1
      const childrenLevel1 = getChildren(categoriesLevel1, categoryLevel0)

      if (childrenLevel1 && childrenLevel1.length > 0) {
        hierarchyDepth = 2
        childrenLevel1.map((categoryLevel1) => {
          const childrenLevel2 = getChildren(categoriesLevel2, categoryLevel1)

          if (childrenLevel2 && childrenLevel2.length > 0) {
            hierarchyDepth = 3
            childrenLevel2.map((categoryLevel2) => {
              const childrenLevel3 = getChildren(categoriesLevel3, categoryLevel2)

              if (childrenLevel3 && childrenLevel3.length > 0) {
                hierarchyDepth = 4
                childrenLevel3.map((categoryLevel3) => {
                  categoryLevel3.label = getCategoryLabelAt(categoryLevel3, 3)
                  categoryLevel3.hierarchyDepth = hierarchyDepth
                  return categoryLevel3
                })
              }

              categoryLevel2.label = getCategoryLabelAt(categoryLevel2, 2)
              categoryLevel2.children = childrenLevel3
              categoryLevel2.hierarchyDepth = hierarchyDepth
              return categoryLevel2
            })
          }

          categoryLevel1.label = getCategoryLabelAt(categoryLevel1, 1)
          categoryLevel1.children = childrenLevel2
          categoryLevel1.hierarchyDepth = hierarchyDepth
          return categoryLevel1
        })
      }
      return {
        value: categoryLevel0.value,
        label: categoryLevel0.value,
        children: childrenLevel1,
        hierarchyDepth,
      }
    })
    return categoriesFacets
  }

  private async fetchAllCategories(): Promise<CategoryHit[]> {
    const hitsPerPage = 1000
    let page = 0
    let allCategories: CategoryHit[] = []
    let hasMoreResults = true

    while (hasMoreResults && page < 5) {
      // eslint-disable-next-line no-await-in-loop
      const result = await this.searchIndexCategories?.search('', {
        hitsPerPage,
        page,
      })

      if (result?.hits && result.hits.length > 0) {
        allCategories = allCategories.concat(result.hits as CategoryHit[])

        if (result.hits.length < hitsPerPage) {
          hasMoreResults = false
        } else {
          page++
        }
      } else {
        hasMoreResults = false
      }
    }

    return allCategories
  }

  private async getCategoriesByPath(): Promise<Record<string, CategoryHit>> {
    const allCategories = await this.fetchAllCategories()

    return allCategories.reduce((acc: Record<string, CategoryHit>, hit: CategoryHit) => {
      if (hit.path) {
        acc[hit.path] = hit
      }
      return acc
    }, {})
  }

  private sortCategoryFacets(
    categoryFacets: CategoryFacet[],
    categoriesByPath: Record<string, CategoryHit>,
  ): CategoryFacet[] {
    categoryFacets.sort((a, b) => categoriesByPath[a.value]?.position - categoriesByPath[b.value]?.position)
    categoryFacets.forEach((categoryFacet) => {
      categoryFacet.slug = categoriesByPath[categoryFacet.value]?.slug
      if (categoryFacet.children && categoryFacet.children.length > 0) {
        categoryFacet.children = this.sortCategoryFacets(categoryFacet.children, categoriesByPath)
      }
    })

    return categoryFacets
  }

  /* eslint-enable no-param-reassign */
  fetchAlgoliaProducts(isCooperative: boolean, filter: string, nbItemsToFetch?: number): Promise<SearchResponse> {
    const secondsSinceEpoch = getSecondsSinceEpoch()
    const filters = [filter, `variants.sellable_until > ${secondsSinceEpoch}`]
    if (isCooperative) {
      filters.push('is_published: true')
    }
    const result = this.searchIndex?.search('', { filters: filters.join(' AND '), hitsPerPage: nbItemsToFetch })
    if (result) {
      return result
    }
    return new Promise((resolve) => {
      resolve({
        hits: [],
        nbHits: 0,
        page: 0,
        nbPages: 0,
        hitsPerPage: 0,
        processingTimeMS: 0,
        exhaustiveNbHits: true,
        query: '',
        params: '',
      })
    })
  }

  async fetchRandomAlgoliaProducts(
    isCooperative: boolean,
    filter: string,
    hitsPerPage: number,
  ): Promise<SearchResponse> {
    const secondsSinceEpoch = getSecondsSinceEpoch()
    const count = localStorage.getItem(`page count for ${filter}`)
    const filters = [filter, `variants.sellable_until > ${secondsSinceEpoch}`]
    if (isCooperative) {
      filters.push('is_published: true')
    }
    const page = Number(count) > 0 ? Math.floor(Math.random() * Number(count)) : 0
    const result = await this.searchIndex?.search('', { filters: filters.join(' AND '), hitsPerPage, page })
    // TODO: que fait si la derniere page contient moins que 5 produits
    if (result) {
      localStorage.setItem(`page count for ${filter}`, String(result.nbPages))
      return result
    }
    return new Promise((resolve) => {
      resolve({
        hits: [],
        nbHits: 0,
        page: 0,
        nbPages: 0,
        hitsPerPage: 0,
        processingTimeMS: 0,
        exhaustiveNbHits: true,
        query: '',
        params: '',
      })
    })
  }

  async fetchSupplierRelatedProducts(
    product: Product,
    isCooperative: boolean,
    itemsToFetch: number,
  ): Promise<SearchResult> {
    const hideSupplierRelatedProducts = product?.categories?.some((path) =>
      EXCLUDED_CATEGORIES_FROM_SUPPLIER_SEARCH.includes(path[0].code),
    )
    if (this.searchIndex != null && !hideSupplierRelatedProducts) {
      const secondsSinceEpoch = getSecondsSinceEpoch()
      const defaultCategoryPath = product.categories[0]
      const longestCategoryPath = defaultCategoryPath.map((cat) => cat.label).join(CATEGORY_SEPARATOR)
      const filters = [
        `supplier:"${product.supplier}"`,
        'supplier_score < 20',
        `variants.sellable_until > ${secondsSinceEpoch}`,
        `categoriesLevel${defaultCategoryPath.length - 1}:"${longestCategoryPath}"`,
        `(NOT objectID:${product._id})`,
      ]
      if (isCooperative) {
        filters.push('is_published: true')
      }
      const related = await this.searchIndex.search('', { filters: filters.join(' AND '), hitsPerPage: itemsToFetch })
      // @ts-ignore: MERCI DE DIRE POURQUOI CE TS IGNORE EST LÀ
      return { hits: related.hits, nbHits: related.nbHits }
    }
    return { hits: [], nbHits: 0 }
  }

  getSuppliers(): Promise<string[]> {
    if (!this.searchIndex) {
      throw new Error('search index need to be initialize')
    }

    // attention : il existe que 95 fournisseurs aujourd'hui donc le maxValuesPerFacet à 500 suffit
    // revoir ce paramètre si ça change
    const facetName = 'supplier'
    return this.searchIndex
      .search('', { facets: [facetName], hitsPerPage: 0, maxValuesPerFacet: 500 })
      .then((response) => {
        const facetValues = response.facets?.[facetName]
        return (facetValues && Object.keys(facetValues)) || []
      })
  }
}
