import { computed, type ComputedRef } from 'vue'
import {
  computeIsOfferOutOfStock,
  isClickAndCollectStockAvailable,
  noStockAndNotAllowedWithoutStock,
} from '@b2ag/stock'
import { ShippingMethodKind } from '@invivodf/module-logistic-offer-client-sdk/dist/convert-logistic-route-to-shipping-method-kind/convert-logistic-route-to-shipping-method-kind'
import type { CooperativeVariant } from '@b2ag/product/src/domain/product'
import { useStock } from '@b2ag/composables/src/catalog/useStock'
import type { Offer } from '../offer'

export interface IUseOfferStock {
  canShipFromStores: ComputedRef<boolean>
  hasStockAvailableInClickAndCollect: ComputedRef<boolean>
  isOfferOutOfStock: ComputedRef<boolean>
  noStockAndNotAllowedWithoutStock: ComputedRef<boolean>
}

export function useOfferStock(
  offer: Offer,
  variant: Pick<CooperativeVariant, 'id' | 'measure_quantity' | 'isSoldAsUnit'>,
): IUseOfferStock {
  const { stock } = useStock(variant)

  const isOfferOutOfStock = computed(() => computeIsOfferOutOfStock(offer, stock.value))
  const hasStockAvailableInClickAndCollect = computed(() => {
    return stock.value?.stores
      .filter(isClickAndCollectStockAvailable(offer))
      .flatMap((s) => s.shipping_methods.map(({ kind }) => kind))
      .some((kind) => kind === ShippingMethodKind.ClickAndCollect)
  })
  const canShipFromStores = computed(() =>
    stock.value.stores
      .flatMap((s) => s.shipping_methods.map(({ kind }) => kind))
      .some((kind) => kind !== ShippingMethodKind.ClickAndCollect),
  )

  return {
    canShipFromStores,
    hasStockAvailableInClickAndCollect,
    isOfferOutOfStock,
    noStockAndNotAllowedWithoutStock: computed(() => noStockAndNotAllowedWithoutStock(offer, isOfferOutOfStock.value)),
  }
}
