import type { MembershipApi } from '@b2ag/membership'
import type {
  DuplicateShoppingListPayloadApi,
  LineApi,
  PaymentDueDateApi,
  SaveLinePayloadApi,
  SaveShoppingListPayloadApi,
  ShoppingListApi,
  ShoppingListStatusApi,
  ShoppingListSubtotalApi,
} from '@aladin/shared-shopping-list-sdk'
import { CheckoutOriginTypeApi } from '@b2ag/checkout'
import { Big } from 'big.js'
import invert from '@b2ag/utils/src/invert'
import {
  CheckoutOriginType,
  type ShoppingList,
  type ShoppingListItem,
  ShoppingListStatus,
} from '../domain/shopping-list.model'

export function shoppingListApiToModel(api: ShoppingListApi): ShoppingList {
  return {
    id: api.id,
    partnerId: api.partner_id,
    title: api.title,
    status: shoppingListStatusApiToShoppingListStatus(api.status),
    lines: mapLinesApiToLinesItems(api.lines),
    updatedAt: api.updated_at,
    comment: api.comment,
    recipient: {
      membershipNumber: api.recipient.membership_number,
      companyName: api.recipient.company_name,
      firstName: api.recipient.first_name,
      lastName: api.recipient.last_name,
      fullName: `${api.recipient.first_name} ${api.recipient.last_name}`,
      isRegistered: api.recipient.isRegistered ?? false,
      phoneNumber: api.recipient.phone_number,
      email: api.recipient.email,
    },
    totalDetails: shoppingListApiTotalDetailsToModel(api),
    checkoutId: api.checkout?.old_id,
    checkoutOriginType: api.checkout?.origin_type && ShoppingListOriginTypeApiToDomain[api.checkout.origin_type],
    errors: api.errors || [],
  }
}

export function shoppingListApiTotalDetailsToModel(api) {
  return {
    articleCount: api.lines.reduce((prev, current) => prev + current.quantity, 0),
    productCount: api.lines.length,
    discounts: api.subtotal.discount,
    dueDates: api.payment_due_dates?.map((pdd) => ({ label: pdd.label, total: pdd.total })),
    tvaDetails: api.subtotal.tva,
    rpdDetails: api.subtotal.rpd,
    totalWithTaxes: api.total || api.totalWithTaxes || '0.00',
    totalWithoutTaxes: subtractDueDatesFromTotalArticles(api.subtotal, api.payment_due_dates),
    totalWithoutVat: subtractDueDatesFromTotalWithoutVat(api.subtotal, api.payment_due_dates),
    estimatedShipping: api.subtotal.estimated_shipping || '0.00',
  }
}

export function modelToSaveShoppingListPayloadApi(shoppingList: ShoppingList): SaveShoppingListPayloadApi {
  return {
    id: shoppingList.id,
    partner_id: shoppingList.partnerId,
    recipient: {
      first_name: shoppingList.recipient?.firstName,
      last_name: shoppingList.recipient?.lastName,
      company_name: shoppingList.recipient?.companyName,
      membership_number: shoppingList.recipient?.membershipNumber,
      phone_number: shoppingList.recipient?.phoneNumber,
      email: shoppingList.recipient?.email,
      isRegistered: shoppingList.recipient?.isRegistered ?? null,
    },
    status: shoppingListStatusToShoppingListApiStatus[shoppingList.status],
    title: shoppingList.title,
    lines: shoppingList.lines.map(lineForOutput),
    comment: shoppingList.comment,
  }
}

export function mapLinesApiToLinesItems(data: LineApi[]): ShoppingListItem[] {
  return data.map((line) => {
    return {
      productId: line.variant.product_id,
      quotas: {
        quantityMax: line.quota?.remaining,
      },
      offer: {
        id: line.offer.id,
        description: line.offer.description,
        deliveryDate: line.shipping.shipping_start_date,
        unitPrice: line.offer.unit_price,
        priceType: line.offer.price_type,
        quantityMin: line.offer.quantity_min,
        quantityMax: line.offer.quantity_max,
        quantityStep: line.offer.quantity_step,
        measurementPrice: line.offer.measurement_price,
      },
      quantity: line.quantity,
      totalPriceWithTaxes: line.total,
      totalPriceWithoutTaxes: line.subtotal.total,
      variant: {
        name: line.variant.variant_name,
        quantityPerUnit: line.variant.variant_measure_quantity,
        unit: line.variant.variant_measure_unit,
        variantIsSoldAsUnit: line.variant.variant_is_sold_as_unit,
        packaging: line.variant.variant_packaging,
        family: line.variant.product_family,
      },
      discount: line.discount && {
        value: parseFloat(line.discount.value ?? '0'),
        unit: line.discount.unit,
        measureUnit: line.discount.measure_unit,
        discountedUnitPrice: line.discount.discounted_unit_price,
        discountedMeasurementPrice: line.discount.discounted_measurement_price,
      },
      taxes: {
        rpdUnitPrice: line.tax.tax_rpd_unit_price,
        tvaUnitPrice: line.tax.tax_tva_unit_price,
        tvaPercent: line.tax.tax_tva_percent,
      },
      shipping: {
        logisticOffers: line.shipping.logistic_offers,
        shippingMethods: line.shipping.shipping_methods,
      },
      clickAndCollectStoreCode: line.click_and_collect?.store_code,
      stock: line.stock,
      paymentDueDate: line.payment_due_date && {
        value: line.payment_due_date.value,
        description: line.payment_due_date.description,
        reference: line.payment_due_date.reference,
        title: line.payment_due_date.title,
      },
    }
  })
}

export function modelToDuplicateShoppingListPayloadApi(params: {
  partnerId: number
  recipient: MembershipApi
}): DuplicateShoppingListPayloadApi {
  return {
    partner_id: params.partnerId,
    recipient: {
      first_name: params.recipient.first_name,
      last_name: params.recipient.last_name,
      company_name: params.recipient.company_name,
      membership_number: params.recipient.membership_number,
      phone_number: params.recipient.phone_number,
      email: params.recipient.email,
      isRegistered: params.recipient.registered ?? null,
    },
  }
}

const lineForOutput = (item: ShoppingListItem): SaveLinePayloadApi => ({
  quantity: item.quantity,
  offer: { id: item.offer.id },
  payment_due_date: item.paymentDueDate && {
    reference: item.paymentDueDate.reference,
    value: item.paymentDueDate.value,
    description: item.paymentDueDate.description,
    title: item.paymentDueDate.title,
  },
  click_and_collect:
    item.clickAndCollectStoreCode === undefined
      ? undefined
      : {
          store_code: item.clickAndCollectStoreCode,
        },
})

const shoppingListApiStatusToShoppingListStatus: Record<ShoppingListStatusApi, ShoppingListStatus> = {
  declined: ShoppingListStatus.DECLINED,
  pending: ShoppingListStatus.PENDING,
  purchased: ShoppingListStatus.PURCHASED,
  sent: ShoppingListStatus.SENT,
  checkout: ShoppingListStatus.CHECKOUT,
}

const shoppingListStatusToShoppingListApiStatus = invert(shoppingListApiStatusToShoppingListStatus) as Record<
  ShoppingListStatus,
  ShoppingListStatusApi
>

export function shoppingListStatusApiToShoppingListStatus(api: ShoppingListStatusApi): ShoppingListStatus {
  return shoppingListApiStatusToShoppingListStatus[api] || ShoppingListStatus.UNKNOWN
}

const ShoppingListOriginTypeApiToDomain: Record<CheckoutOriginTypeApi, CheckoutOriginType> = {
  shopping_list: CheckoutOriginType.SHOPPING_LIST_TYPE,
  purchase_order: CheckoutOriginType.PURCHASE_ORDER_TYPE,
}
const sumOfDueDates = (paymentDueDates?: PaymentDueDateApi[] | undefined): number => {
  return paymentDueDates?.map((dueDate) => +dueDate.total).reduce((a, b) => a + b, 0) ?? 0
}

const subtractDueDatesFromTotalArticles = (
  subtotal: ShoppingListSubtotalApi,
  paymentDueDates?: PaymentDueDateApi[] | undefined,
) => {
  const result = parseFloat(subtotal.total || '0.00') - sumOfDueDates(paymentDueDates)
  return result ? `${result}` : '0.00'
}
const subtractDueDatesFromTotalWithoutVat = (
  subtotal: ShoppingListSubtotalApi,
  paymentDueDates?: PaymentDueDateApi[] | undefined,
) => {
  const total = Big(subtotal.total || '0.00')
    .add(subtotal.rpd || '0.00')
    .toFixed(2)
  const result = parseFloat(total) - sumOfDueDates(paymentDueDates)
  return result ? `${result}` : '0.00'
}
