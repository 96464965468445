import algoliasearch from 'algoliasearch/lite'
import ContentfulClient from '@b2ag/contentful'
import { AlgoliaSearchService } from '@b2ag/search'
import { getConfig } from '@b2ag/micro-front-tools'

export { productService } from '@b2ag/product/src/dependency-injection'

const contentfulSpaceId = getConfig('CONTENTFUL_SPACE_ID')
const contentfulApiKey = getConfig('CONTENTFUL_API_KEY')
const environmentName = getConfig('ENVIRONMENT_NAME')
const searchAppId = getConfig('ALGOLIA_APP_ID')
const searchApiKey = getConfig('ALGOLIA_API_KEY')
export const searchIndex = getConfig('ALGOLIA_INDEX')
export const searchIndexCoop = getConfig('ALGOLIA_INDEX_COOP_PREFIX')

const algoliaClient = algoliasearch(searchAppId, searchApiKey)

const contentfulClient = new ContentfulClient({ contentfulSpaceId, contentfulApiKey, environmentName })
const searchService = new AlgoliaSearchService(algoliaClient)

export { contentfulClient, searchService }
