import { getAppContext } from '@b2ag/micro-front-tools'
import { AddressReactivitySettings } from '@b2ag/soufflet-pricer'
import { Store } from '@b2ag/store'

export function makeAddressReactivitySettings(store: Store): AddressReactivitySettings {
  const { appFrontType } = getAppContext()

  switch (appFrontType) {
    case 'agri':
      return {
        cartTypeLabel: 'panier',
        shouldRevalidate: () => store.getters['cart/containsPricerOffers'],
        onRevalidatedConfirm: () => store.dispatch('cart/deleteCart'),
      }
    case 'tech':
      return {
        cartTypeLabel: 'devis',
        shouldRevalidate: () => store.getters['shoppingList/containsPricerOffers'],
        onRevalidatedConfirm: () => store.dispatch('shoppingList/removeCurrentShoppingList'),
      }
    default:
      throw new Error(`Unknown appFrontType: ${appFrontType}`)
  }
}
