import { getConfig, messagesForParent } from '@b2ag/micro-front-tools'
import type { DataForParent } from '@b2ag/micro-front-tools'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'

function callbackOrderCreated(storeInstance: any) {
  storeInstance.dispatch('cart/resetCart')
}

export function callbacksFromCheckoutMicroFrontIframe(data: DataForParent, storeInstance: any) {
  switch (data.message) {
    case messagesForParent.CHECKOUT_ORDER_DONE:
      callbackOrderCreated(storeInstance)
      break
    default:
      break
  }
}

export function routingCancelCheckout(paramsCheckoutId: string, storeInstance?: any) {
  const checkoutId =
    storeInstance?.getters['cart/cart']?.checkout_id ||
    storeInstance?.getters['list/list']?.checkout_id ||
    paramsCheckoutId

  if (checkoutId)
    return createAxiosClientWithAladinBearer(`${getConfig('ORDER_API_HOST')}/v1`, { callerId: buildCallerId() }).delete(
      `/checkouts/${checkoutId}`,
    )
  return Promise.resolve(undefined)
}

export enum CheckoutOriginTypeApi {
  SHOPPING_LIST_TYPE = 'shopping_list',
  PURCHASE_ORDER_TYPE = 'purchase_order',
}
