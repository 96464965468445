import type { AxiosInstance } from '@b2ag/axios'
import isEmpty from 'lodash/isEmpty'
import type { ProductGuestResponse } from '@b2ag/types-api-schemas'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import {
  type ProductSDK,
  type ProduitB2AgWithoutProductsInPackApiResponse,
  type ProduitCatalogueCooperativeB2AgApiResponse,
  ProduitType,
} from '@b2ag/module-product-sdk'
import type { CooperativeProduct, FlattenedPackProduct, Product, ProductRedirect } from './product'
import { isProductRedirectUrl } from './product.business'
import { mapFromApi as mapProductFromApi } from './product.mapper'
import { mapFromApi as mapProductListFromApi } from './productList.mapper'
import { ProductRedirectError } from './product'

export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR'

class ProductService {
  readonly api: AxiosInstance

  constructor(
    productApiHost: string,
    private sdk: ProductSDK,
  ) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1`, { callerId: buildCallerId() })
  }

  async findProductId(eanCode): Promise<number | undefined> {
    const products = await this.sdk.getProducts({
      ean_code: eanCode,
    })
    const { _id: id }: any = products[0] || {}
    return id
  }

  async get(id: string, cooperativeId?: number): Promise<CooperativeProduct> {
    let response
    if (cooperativeId) {
      response = await this.api.get<ProduitCatalogueCooperativeB2AgApiResponse>(
        `/cooperatives/${cooperativeId}/products/${id}`,
      )
    } else {
      response = await this.api.get<ProductGuestResponse | ProductRedirect>(`/products/${id}`)
      if (isProductRedirectUrl(response)) throw new ProductRedirectError(response.data.redirectUrl)
    }
    const product = mapProductFromApi(response.data)
    return ProductService.isPackProduct(product)
      ? ProductService.flattenPackProduct(product, response.data.products_in_pack)
      : product
  }

  async getCatalog(id: string, cooperativeId: string): Promise<CooperativeProduct> {
    try {
      const response = await this.api.get<ProduitCatalogueCooperativeB2AgApiResponse>(
        `/cooperatives/${cooperativeId}/products/${id}`,
      )
      const product = mapProductFromApi(response.data)
      return ProductService.isPackProduct(product)
        ? ProductService.flattenPackProduct(product, response.data.products_in_pack!)
        : product
    } catch (e: any) {
      throw new Error(FETCH_PRODUCT_ERROR)
    }
  }

  static isPackProduct(product: CooperativeProduct): boolean {
    return product.type === ProduitType.PACK
  }

  static flattenPackProduct(
    packProduct: Product | CooperativeProduct,
    productsInPack: ProduitB2AgWithoutProductsInPackApiResponse[],
  ): FlattenedPackProduct {
    const flattenedProduct = { ...packProduct } as any as FlattenedPackProduct

    const fieldsToFlatten = [
      'formulation',
      'market_authorization',
      'toxicities',
      'usages_by_crops',
      'active_substances',
    ]

    fieldsToFlatten.forEach((field) => {
      flattenedProduct[field] = productsInPack!.map((product) => {
        if (field === 'usages_by_crops') {
          return { id: product._id, product_name: product.name, value: product[field] }
        }
        return { product_name: product.name, value: product[field] }
      })
    })

    // eslint-disable-next-line camelcase
    flattenedProduct.safetySheetUrls = productsInPack!
      // eslint-disable-next-line camelcase
      .filter((product) => product.safety_sheet_urls)
      .map((product) => ({
        product_name: product.name,
        // eslint-disable-next-line camelcase
        value: product.safety_sheet_urls,
      }))

    return flattenedProduct
  }

  async getHighlighted(cooperativeId: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<ProduitCatalogueCooperativeB2AgApiResponse[]>(
      `/cooperatives/${cooperativeId}/products`,
      {
        params: { highlighted: true },
      },
    )
    return mapProductListFromApi(response.data)
  }

  async getOffSeason(cooperativeId: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<ProduitCatalogueCooperativeB2AgApiResponse[]>(
      `/cooperatives/${cooperativeId}/products`,
      {
        params: { off_season: true },
      },
    )
    return mapProductListFromApi(response.data)
  }

  async findProducts(idList: string[], cooperativeId?: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<ProduitCatalogueCooperativeB2AgApiResponse[]>(
      cooperativeId ? `/cooperatives/${cooperativeId}/products` : '/products',
      {
        params: {
          _id: idList.join(','),
          limit: 100 /** se poser la question si on devrait récupérer autant de produits */,
        },
      },
    )
    return response.data.map((responseProduct) => {
      const product = mapProductFromApi(responseProduct)
      return ProductService.isPackProduct(product)
        ? ProductService.flattenPackProduct(product, responseProduct.products_in_pack!)
        : product
    })
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable(product: Product): boolean {
    if (!product || product._id === 'ID_NOT_FOUND' || isEmpty(product.variants)) {
      return false
    }

    const isVariantAvailable = (variant) => !isEmpty(variant.offers)
    return product.variants.some(isVariantAvailable)
  }
}

export default ProductService
