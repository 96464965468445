import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { isFarmi } from '@b2ag/app-brands'

class IdfTrackingService {
  api: AxiosInstance

  constructor(idfTrackingApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${idfTrackingApiHost}/v1/events/`, { callerId: buildCallerId() })
  }

  // eslint-disable-next-line camelcase
  async trackProductViewed(membership_number: String, coop_id: Number, coop_name: string, product) {
    try {
      if (!isFarmi) {
        const response = await this.api.post('/products-viewed', {
          membership_number,
          coop_id,
          coop_name,
          product_id: product.id,
          category_level_0: product.level_categorie_0,
          category_level_1: product.level_categorie_1,
          offers_ids: product.offers_ids,
        })
        return response.data
      }
      return Promise.resolve()
    } catch {
      return Promise.resolve()
    }
  }
}

export default IdfTrackingService
