import { createMembershipApiClient } from '@aladin/shared-universcoop-sdk'
import { AlgoliaSearchService } from '@b2ag/search'
import OfferService from '@b2ag/offers/src/services/offer.service'
import algoliasearch from 'algoliasearch/lite'
import { AuthLocationStorage, useAuthService } from '@b2ag/auth/dist/esm'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { StockService } from '@b2ag/stock'
import { DiscountService } from '@b2ag/discount'
import { StoreService } from '@b2ag/coop-store'
import RecommendationService from '@b2ag/product/src/services/recommendation.service'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import CartService from '@b2ag/cart/src/cart.service'
import LineItemService from '@b2ag/cart/src/line-item.service'
import { useLogger } from '@b2ag/composables/src/useLogger'
import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { MembershipService } from '@b2ag/membership/src/services/membership.service'
import CooperativeService from './cooperative.service'
import UserService from './user.service'
import ProfileService from './profile.service'
import IdfTrackingService from './idf-tracking.service'

export { productService } from '@b2ag/product/src/dependency-injection'

window.env = window.env || {}

const orderApiHost = window.env.ORDER_API_HOST
const cartApiHost = window.env.CART_API_HOST
const productApiHost = window.env.PRODUCT_API_HOST
const discountApiHost = window.env.DISCOUNT_API_HOST
const userApiHost = window.env.USER_API_HOST
const searchAppId = window.env.ALGOLIA_APP_ID
const searchApiKey = window.env.ALGOLIA_API_KEY
const idfTrackingApiHost = window.env.IDF_TRACKING_API_HOST!
const shoppingListApiHost = window.env.SHOPPING_LIST_API_HOST!

const accessTokenSource = () => useAuthService().getAccessToken()
const logger = useLogger()
const callerId = buildCallerId()

export const algoliaClient = algoliasearch(searchAppId, searchApiKey)
export const searchService = new AlgoliaSearchService(algoliaClient)
export const cooperativeService = new CooperativeService(userApiHost)
export const cartService = new CartService({ cartApiHost })
export const lineItemService = new LineItemService({ cartApiHost })
export const offerService = new OfferService(createAxiosClientWithAladinBearer(productApiHost, { callerId }))
export const profileService = new ProfileService(userApiHost)
export const discountService = new DiscountService({ discountApiHost })
export const recommendationService = new RecommendationService(productApiHost)
export const userService = new UserService(userApiHost)
export const stockService = new StockService(orderApiHost)
export const storeService = new StoreService({ userApiHost })

export const membershipApiClient = createMembershipApiClient({
  membershipApiHost: userApiHost,
  accessTokenSource,
  logger,
})
export const idfTrackingService = new IdfTrackingService(idfTrackingApiHost)
export const locationStorage = new AuthLocationStorage(localStorage)
export const membershipService = new MembershipService({ userApiHost })
export const shoppingListApiClient = createShoppingListApiClient({
  shoppingListApiHost,
  useCartV2: true,
  accessTokenSource,
  logger,
})
