import isEmpty from 'lodash/isEmpty'
import toInteger from 'lodash/toInteger'
import toString from 'lodash/toString'
import { mapFromApi as mapCooperativeFromApi, mapToApi as mapCooperativeToApi } from './cooperative.mapper'

interface ShippingAddressApi {
  id: number
  city: string
  code: string
  company_name: string
  contact: string
  country_code: string
  line1: string
  line2: string
  line3: string
  zip_code: string
  membership_id: number
  created_at: string
  updated_at: string
}

interface ShippingAddress {
  id: number
  city: string
  code: string
  companyName: string
  contact: string
  countryCode: string
  line1: string
  line2: string
  line3: string
  zipCode: string
  membershipId: number
  createdAt: string
  updatedAt: string
}

function mapShippingAddressesFromApi(shippingAddresses: ShippingAddressApi[]): ShippingAddress[] {
  return shippingAddresses.map((address) => ({
    id: address.id,
    city: address.city,
    code: address.code,
    companyName: address.company_name,
    contact: address.contact,
    countryCode: address.country_code,
    line1: address.line1,
    line2: address.line2,
    line3: address.line3,
    zipCode: address.zip_code,
    membershipId: address.membership_id,
    createdAt: address.created_at,
    updatedAt: address.updated_at,
  }))
}

export function mapFromApi(profile: any = {}) {
  const {
    id,
    email,
    company_name: companyName,
    consent,
    membership_number: membershipNumber,
    cooperative,
    payment_methods: paymentMethods = [],
    technical_sales: technicalSales = [],
    shipping_addresses: shippingAddresses = [],
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    siret_number: siretNumber,
    valid_certiphyto: validCertiphyto,
    certiphyto_end_date: certiphytoEndDate,
    valid_explosives_precursor: validExplosivesPrecursor,
    explosives_precursor_end_date: explosivesPrecursorEndDate,
    confirmed_at: confirmedAt,
    crops,
  } = profile

  if (isEmpty(toString(id))) return {}

  return {
    id: toString(id),
    email,
    companyName,
    consent,
    membershipNumber,
    cooperative: mapCooperativeFromApi(cooperative),
    paymentMethods,
    technicalSales,
    firstName,
    lastName,
    phoneNumber,
    siretNumber,
    // TODO: maybe a Certificate domain ?
    validCertiphyto,
    certiphytoEndDate,
    validExplosivesPrecursor,
    explosivesPrecursorEndDate,
    confirmedAt,
    crops,
    shippingAddresses: mapShippingAddressesFromApi(shippingAddresses),
  }
}

export function mapToApi(profile: any = {}) {
  const {
    id,
    email,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    consent,
    membershipNumber,
    siretNumber,
    cooperative,
    paymentMethods,
    technicalSales,
    validCertiphyto,
    certiphytoEndDate,
    validExplosivesPrecursor,
    explosivesPrecursorEndDate,
  } = profile

  if (isEmpty(toString(id))) return {}

  return {
    id: toInteger(id),
    email,
    company_name: companyName,
    consent,
    membership_number: membershipNumber,
    cooperative: mapCooperativeToApi(cooperative),
    technical_sales: technicalSales,
    payment_methods: paymentMethods,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    siret_number: siretNumber,
    valid_certiphyto: validCertiphyto,
    certiphyto_end_date: certiphytoEndDate,
    valid_explosives_precursor: validExplosivesPrecursor,
    explosives_precursor_end_date: explosivesPrecursorEndDate,
  }
}
