/* eslint-disable no-use-before-define,no-param-reassign */
import type { Membership as MembershipApi } from '@b2ag/membership'
import type { ShoppingListApi, ShoppingListMetadataApi } from '@aladin/shared-shopping-list-sdk'
import { ShoppingListStatusApi } from '@aladin/shared-shopping-list-sdk'
import { CheckoutOriginTypeApi } from '@b2ag/checkout'
import invert from '@b2ag/utils/src/invert'
import isNil from 'lodash/isNil'
import { CheckoutOriginType, ShoppingListInfoStatus } from '../domain/membership.interface'
import type {
  CountByStatus,
  Membership,
  ShoppingListInfo,
  ShoppingListInfoFamily,
  ShoppingListInfoRecipient,
} from '../domain/membership.interface'

export function shoppingListsApiToShoppingListsInfo(shoppingListsApi: ShoppingListApi[]): ShoppingListInfo[]
export function shoppingListsApiToShoppingListsInfo(
  shoppingListsApi: ShoppingListApi[] | null,
): ShoppingListInfo[] | null
export function shoppingListsApiToShoppingListsInfo(
  shoppingListsApi: ShoppingListApi[] | null,
): ShoppingListInfo[] | null {
  if (shoppingListsApi === null) return null
  return shoppingListsApi.map((shoppingListApi) => {
    return shoppingListApiToShoppingListInfo(shoppingListApi)
  })
}

export function shoppingListApiToShoppingListInfo(api: ShoppingListApi): ShoppingListInfo
export function shoppingListApiToShoppingListInfo(api: ShoppingListApi | null): ShoppingListInfo | null
export function shoppingListApiToShoppingListInfo(api: ShoppingListApi | null): ShoppingListInfo | null {
  if (api === null) return null
  return {
    id: api.id,
    title: api.title,
    items: api.lines.length,
    totalWithoutTaxes: api.subtotal?.total || '0.00',
    status: shoppingListStatusApiToShoppingListStatus(api.status),
    families: mapItemsListToFamilies(api.lines),
    updatedAt: api.updated_at,
    checkoutOriginType: api.checkout?.origin_type && ShoppingListOriginTypeApiToDomain[api.checkout.origin_type],
    recipient: mapRecipientApiToShoppingListInfoRecipient(api.recipient),
  }
}

export function mapItemsListToFamilies(data: ShoppingListApi['lines']): ShoppingListInfo['families'] {
  const families: ShoppingListInfoFamily[] = Object.entries(
    data.reduce<Record<string, number>>((acc, line) => {
      const family = line.variant.product_family
      return {
        ...acc,
        [family]: (acc[family] || 0) + 1,
      }
    }, {}),
  ).map(([name, countItems]) => ({ name, countItems }))

  return families
}

export function mapRecipientApiToShoppingListInfoRecipient(
  data: ShoppingListApi['recipient'],
): ShoppingListInfoRecipient {
  return {
    companyName: data.company_name,
    firstName: data.first_name,
    lastName: data.last_name,
    membershipNumber: data.membership_number,
  }
}

export function mapMetadataApiToCountsByStatus(metadata: ShoppingListMetadataApi): CountByStatus {
  return Object.keys(ShoppingListStatusApi).reduce((countsByStatus, statusApi) => {
    const status = shoppingListApiStatusToShoppingListStatus[statusApi]
    return {
      ...countsByStatus,
      [status]: metadata.count_by_status[statusApi],
    }
  }, {})
}

const shoppingListApiStatusToShoppingListStatus: Record<ShoppingListStatusApi, ShoppingListInfoStatus> = {
  declined: ShoppingListInfoStatus.DECLINED,
  pending: ShoppingListInfoStatus.PENDING,
  purchased: ShoppingListInfoStatus.PURCHASED,
  sent: ShoppingListInfoStatus.SENT,
  checkout: ShoppingListInfoStatus.CHECKOUT,
}

const shoppingListStatusToShoppingListStatusApi = invert(shoppingListApiStatusToShoppingListStatus) as Record<
  ShoppingListInfoStatus,
  ShoppingListStatusApi
>

export function shoppingListStatusApiToShoppingListStatus(api: ShoppingListStatusApi): ShoppingListInfoStatus {
  return shoppingListApiStatusToShoppingListStatus[api]
}

export function mapShoppingListStatusToShoppingListStatusApi(status: ShoppingListInfoStatus): ShoppingListStatusApi {
  return shoppingListStatusToShoppingListStatusApi[status]
}

export function getMembershipShoppingListItem(
  memberships: MembershipApi[],
  shoppingListData: ShoppingListApi[],
): Membership[] {
  return memberships.map((membershipApi) => ({
    id: membershipApi.id,
    firstName: membershipApi.first_name,
    lastName: membershipApi.last_name,
    registered: membershipApi.registered ?? false,
    membershipNumber: membershipApi.membership_number,
    validCertiphyto: membershipApi.valid_certiphyto,
    companyName: membershipApi.company_name,
    phoneNumber: membershipApi.phone_number,
    email: membershipApi.email,
    warnings: membershipApi.warnings,
    shoppingListsInfo: isNil(shoppingListData)
      ? null
      : shoppingListsApiToShoppingListsInfo(
          shoppingListData.filter(
            (shoppingList) => shoppingList.recipient.membership_number === membershipApi.membership_number,
          ) || null,
        ),
  }))
}

export function mapMembershipApiToMembership(membership: MembershipApi): Membership {
  return {
    id: membership.id,
    firstName: membership.first_name,
    lastName: membership.last_name,
    companyName: membership.company_name,
    registered: membership.registered ?? false,
    membershipNumber: membership.membership_number,
    validCertiphyto: membership.valid_certiphyto,
    shoppingListsInfo: null,
    phoneNumber: membership.phone_number,
    email: membership.email,
    warnings: membership.warnings,
  }
}

const ShoppingListOriginTypeApiToDomain: Record<CheckoutOriginTypeApi, CheckoutOriginType> = {
  // @ts-ignore - TODO XJU : revoir l'implémentation dans le SDK
  [CheckoutOriginTypeApi.SHOPPING_LIST_TYPE]: CheckoutOriginType.SHOPPING_LIST_TYPE,
  // @ts-ignore - TODO XJU : revoir l'implémentation dans le SDK
  [CheckoutOriginTypeApi.PURCHASE_ORDER_TYPE]: CheckoutOriginType.PURCHASE_ORDER_TYPE,
}
