import type { SaveLinePayloadApi, ShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { ShoppingListStatusApi } from '@aladin/shared-shopping-list-sdk'
import type { LineRef, ShoppingListRef } from '@b2ag/shopping-list/src/domain/shopping-list.model'

export interface ShoppingListRepository {
  addOfferToShoppingList(shoppingListId: string, line: LineRef): Promise<void>
  fetchLastShoppingListForMembership(membershipNumber: string): Promise<ShoppingListRef | null>
}

export function createShoppingListRepository(deps: Dependencies): ShoppingListRepository {
  const { logger, shoppingListDatasource } = deps

  async function addOfferToShoppingList(shoppingListId: string, line: LineRef): Promise<void> {
    logger.debug('Adding line to shopping list %s', shoppingListId)
    await shoppingListDatasource.addLineToShoppingList(shoppingListId, mapToSaveLinePayloadApi(line))
  }

  async function fetchLastShoppingListForMembership(membershipNumber: string): Promise<ShoppingListRef | null> {
    logger.debug('Fetching shopping list for membership %s', membershipNumber)
    const shoppingList = await shoppingListDatasource.fetchOwnedShoppingLists({
      membershipNumbers: [membershipNumber],
      status: ShoppingListStatusApi.pending,
    })
    if (shoppingList.length < 1) {
      return null
    }
    return shoppingList[0]
  }

  return {
    addOfferToShoppingList,
    fetchLastShoppingListForMembership,
  }
}

interface Dependencies {
  shoppingListDatasource: ShoppingListApiClient
  logger: any
}

function mapToSaveLinePayloadApi(line: LineRef): SaveLinePayloadApi {
  return {
    quantity: line.quantity,
    offer: line.offer,
    payment_due_date: line.paymentDueDate,
    click_and_collect: line.clickAndCollect && {
      store_code: line.clickAndCollect.storeCode,
    },
  }
}
