let singl_instance: ReturnType<typeof initMiddleware>

interface RedirectionApiResponse {
  /** The source url */
  source_url: string
  /** The destination url */
  destination_url: string
  /** The redirection type */
  redirection_type: number
}

interface SDKRedirectUrl {
  getRedirection: (sourceUrl: string) => Promise<RedirectionApiResponse | null>
}

function initMiddleware(externalSDK: SDKRedirectUrl): {
  beforeRouteEnter: (to: any, _: any, next: any) => Promise<void>
  metaInfo: () => { meta: { name: string; content: string }[] } | void
} {
  const cachedRedirectAPIMap = new Map<string, [string, number]>()
  const executedRedirects = new Map<string, [string, number]>()

  async function getRedirection(path: string): Promise<[string, number] | undefined> {
    const cachedRedirect = cachedRedirectAPIMap.get(path)

    if (cachedRedirect) return cachedRedirect

    return externalSDK
      .getRedirection(path)
      .then((data) => {
        if (!data) cachedRedirectAPIMap.set(path, [path, 200])
        else cachedRedirectAPIMap.set(path, [data.destination_url, data.redirection_type])

        return cachedRedirectAPIMap.get(path)
      })
      .catch(() => {
        return undefined
      })
  }

  async function beforeRouteEnter(to, _, next): Promise<void> {
    const redirectData = await getRedirection(to.fullPath)

    if (redirectData && redirectData[0] !== to.fullPath) {
      const [redirectUrl, statusCode] = redirectData

      executedRedirects.set(decodeURI(redirectUrl), [to.fullPath, statusCode])

      next({ path: redirectUrl })
    } else next()
  }

  // eslint-disable-next-line consistent-return
  function metaInfo(): { meta: { name: string; content: string }[] } | void {
    const [originalFullPath, statusCode] =
      executedRedirects.get(decodeURI(window.location.href.split(window.location.origin)[1])) || []

    if (originalFullPath) {
      return {
        meta: [
          { name: 'prerender-status-code', content: statusCode?.toString() || '301' },
          { name: 'prerender-header', content: `Location: ${originalFullPath}` },
        ],
      }
    }
  }

  return { beforeRouteEnter, metaInfo }
}

export function initRedirectUrl(externalSDK) {
  singl_instance = initMiddleware(externalSDK)
}

export function useRedirectUrl() {
  return singl_instance
}
