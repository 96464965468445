export enum TEXT_COLOR {
  MAIN = 'MAIN',
  TEXT_LIGHT = 'TEXT_LIGHT',
}

export enum FONT_SIZE {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}
