/* eslint-disable no-console */
export type LoggerLevel = 'debug' | 'info' | 'warn' | 'error'

const levelToWeight: Record<LoggerLevel, number> = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
}

let currentWeight = levelToWeight.info

export function setLoggerLevel(level: LoggerLevel = 'warn') {
  currentWeight = levelToWeight[level] ?? levelToWeight.info
}

export function useLogger(name: string = 'default') {
  return {
    debug(message, ...params) {
      if (currentWeight <= levelToWeight.debug) {
        console.debug(`${name} : ${message}`, ...params)
      }
      return this
    },
    info(message, ...params) {
      if (currentWeight <= levelToWeight.info) {
        console.info(`${name} : ${message}`, ...params)
      }
      return this
    },
    warn(message, ...params) {
      if (currentWeight <= levelToWeight.warn) {
        console.warn(`${name} : ${message}`, ...params)
      }
      return this
    },
    error(message, ...params) {
      if (currentWeight <= levelToWeight.error) {
        console.error(`${name} : ${message}`, ...params)
      }
      return this
    },
  }
}
