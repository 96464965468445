import * as SentryCopy from '@sentry/vue'
import { Options as SentryOptions } from '@sentry/vue/types/types'

type SentryParams = Omit<SentryOptions, 'attachProps' | 'logErrors' | 'trackComponents' | 'timeout' | 'hooks'>

export interface SentryConfig {
  router: any
  params: SentryParams
}

export const initSentry = ({ router, params }: SentryConfig) => {
  if (window.location.hostname.includes('localhost')) return
  if (!sentryIsEnabled) return

  const sentryParams: SentryParams = {
    ...params,
    integrations: [
      new SentryCopy.BrowserTracing({
        routingInstrumentation: SentryCopy.vueRouterInstrumentation(router),
      }),
    ],
  }

  if (params.Vue) sentryParams.Vue = params.Vue
  else if (params.app) sentryParams.app = params.app
  else throw new Error('Vue instance not found')

  SentryCopy.init(sentryParams)
}

export const sentryIsEnabled = window.env.SENTRY_ENABLED === 'yes'

export default SentryCopy
