import createProductSdk from '@b2ag/module-product-sdk'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { useAuthService } from '@b2ag/auth'
import { useLogger } from '@b2ag/composables/src/useLogger'
import { productApiHost } from './config'
import ProductService from './services/product.service'

const accessTokenSource = () => useAuthService().getAccessToken()
const logger = useLogger()
const callerId = buildCallerId()

export const productApiClient = createProductSdk(`${productApiHost}`, { callerId, accessTokenSource, logger })

export const productService = new ProductService(productApiHost, productApiClient)
