/* eslint-disable no-underscore-dangle */

export default class AdnSelectOptionProps {
  payload: any = undefined
  iconName: string | undefined = undefined
  image: string | undefined = undefined
  defaultImageClass: string | undefined = undefined
  text: string | undefined = undefined
  textComplement: string | undefined = undefined
  disabled: boolean = false
  checkbox: boolean = false
  selected: boolean = false

  constructor({
    payload,
    iconName,
    image,
    defaultImageClass,
    text,
    textComplement,
    disabled,
    checkbox,
    selected,
  }: {
    payload: any
    iconName?: string
    image?: string
    defaultImageClass?: string
    text?: string
    textComplement?: string
    disabled?: boolean
    checkbox?: boolean
    selected?: boolean
  }) {
    this.payload = payload
    this.iconName = iconName || undefined
    this.image = image || undefined
    this.defaultImageClass = defaultImageClass || undefined
    this.text = text || payload || undefined
    this.textComplement = textComplement || undefined
    this.disabled = disabled || false
    this.checkbox = checkbox || false
    this.selected = selected || false
  }
}
