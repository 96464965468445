import { getAppContext } from '@b2ag/micro-front-tools'

const { appFrontType } = getAppContext()

const storeAgri = () => import(/* webpackChunkName: "Store.agri" */ './fronts/agri/store/store')
const storeTech = () => import(/* webpackChunkName: "Store.tech" */ './fronts/tech/store/store')

let store: any

export async function initStore() {
  store = appFrontType === 'agri' ? (await storeAgri()).default : (await storeTech()).default

  return store
}

export function getStore() {
  return store
}
