import type { LineRef } from '@b2ag/shopping-list/src/domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

export function createAddLineToShoppingListUsecase(deps: Dependencies): AddLineToShoppingListUsecase {
  const { logger, shoppingListRepository } = deps

  return async (shoppingListId: string, line: LineRef): Promise<void> => {
    logger.debug('Adding line to shopping list')
    await shoppingListRepository.addOfferToShoppingList(shoppingListId, line)
  }
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
  logger: any
}

export interface AddLineToShoppingListUsecase {
  (shoppingListId: string, line: LineRef): Promise<void>
}
